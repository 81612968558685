
import { defineComponent, ref } from "vue";
import firebase from 'firebase'
import { useToast } from "vue-toastification";
import axios from "axios";
import router from "@/router";

interface NewAddressData {
  targetTitle: string;
  assign: string;
  dueDate: string;
  targetDetails: string;
  tags: Array<string>;
}

export default defineComponent({
  name: "new-target-modal",
  components: {},
  methods: {
    posterError(e){
      useToast().error(e)
    },
    uploadPoster(e){
      this.loading = true
      firebase.storage().ref().child(`${new Date().getTime()}`).put(e.target.files[0]).then(async snapshot=>{
        this.loading = false
        this.survey.poster = await snapshot.ref.getDownloadURL()
      }, err =>{
        this.loading = false
        console.log('fuck error: ', err)
      })
    },
    draft(e){
      console.log(e.target)
      console.log(this.survey.status)
    }
  },
  setup(props, {emit}) {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const uploading = ref<boolean>(false);
    const limit = ref<boolean>(false);

    const survey = ref({
      company: {
        name: 'MahalaAirtime',
        logo: 'https://www.mahalaairtime.com/assets/logo.7e0c49b1.svg'
      },
      end: "",
      tags: [],
      title: "",
      description: "",
      poster: "",
      type: "survey",
      content: "",
      category: "",
      facebook: [],
      connections: [],
      feed: [],
      whatsapp: [],
      status: 'pending',
      uploaded: new Date(),
      users: [],
      limit: 200
    });

    const rules = ref({
      title: [
        {
          required: true,
          message: "Please input survey name",
          trigger: "blur",
        },
      ],
      end: [
        {
          required: true,
          message: "Please select survey end date",
          trigger: "change",
        },
      ],
      tags: [
        {
          required: true,
          message: "Please add tags for survey",
          trigger: "change",
        },
      ],
    });

    const submit = () => {

      loading.value = true;

      if(uploading.value){
        return setTimeout(submit, 5000)
      }else{

        if(limit.value){
          if(survey.value.limit == 0){
            loading.value = false
            return useToast().warning('Please specify limit of responses to auto terminate survey.')
          }
        }else{
          survey.value.limit = 0
        }

        axios.post('/surveys', survey.value).then(res=>{
          loading.value = false;
          useToast().success(res.data.message)
          document.getElementById('modal-close').click()
          emit('refresh', true)
        }).catch(e=>{
          loading.value = false;
          try{
            if(e.response.status == 401){
              useToast().warning('Your session has expired, please reauthenticate')
              return router.replace('/sign-in')
            }else{
              useToast().error(e.response.data.message)
            }
          }catch(err){
            useToast().error(e.message)
          }
        })
      }
    };

    return {
      survey,
      submit,
      loading,
      uploading,
      formRef,
      rules,
      limit
    };
  },
});
