
import { defineComponent, ref } from "vue";
import NewTargetModal from "@/components/modals/forms/NewTargetModal.vue";
import Dropdown4 from "@/components/dropdown/Dropdown4.vue";
import { ScrollComponent } from "@/assets/ts/components";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "private-chat",
  components: {
    MessageIn,
    MessageOut,
    Dropdown4,
    NewTargetModal
  },
  data(){
    return {
      surveys: [],
      filtered: [],
      responses: [],
      questions: [],
      answers: ['', ''],
      query: '',
      selected: null,
      loading: false,
      newMessageText: '',
      messagesRef: ref<null | HTMLElement>(null)
    }
  },
  methods: {
    getall(){
      this.responses = []
      this.axios.get('/surveys').then(({data})=>{
        this.surveys = data.surveys
        this.filtered = data.surveys
        this.getsurvey(data.surveys[0])
      }).catch(e=>{
        console.error(e)
      })
    },
    search(e){
      if(e.data != null){
        this.query += e.data
      }else{

        if(this.query.length > 0){
          this.query = this.query.substring(0, this.query.length-1)
        }
      }

      this.filtered = this.surveys.filter(single=>single.title.toLowerCase().includes(this.query.toLowerCase()) || single.description.toLowerCase().includes(this.query.toLowerCase()))
    },
    getsurvey(item){
      this.selected = item
      this.responses = []
      this.questions = []
      this.axios.get(`/surveys/responses/${this.selected.id}`).then(res=>{
        this.responses = res.data.responses
      }).catch(e=>{
        console.error(e)
      })
      this.axios.get(`/questions/${this.selected.id}`).then(res=>{
        this.questions = res.data.questions
      }).catch(e=>{
        console.error(e)
      })
    },

    stopsurvey(){
      this.axios.delete(`/content/${this.selected.id}`).then(({data})=>{
        useToast().success(data.message)
        this.selected = null
        this.getall()
      }).catch(e=>{
        try{
          if(e.response.status == 401){
            useToast().warning('Your session has expired, please log back in')
            this.$router.replace('/sign-in')
          }else{
            useToast().error(e.response.data.message)
          }
        }catch(err){
          useToast().warning(e.message)
        }
      })
    },

    addquestion(){
      if (this.newMessageText.trim().length == 0) {
        return useToast().warning('Please type in your survey question')
      }

      if(this.answers[0].trim().length == 0 || this.answers[1].trim().length == 0){
        return useToast().warning('Please provide atleast two possible answers')
      }

      let question = this.newMessageText.trim()
      let answers = this.answers

      this.axios.post('/surveys/question', {
        id: this.selected.id,
        question,
        answers
      }).then(()=>{
        useToast().success('Your question was added to the survey.')
        this.newMessageText = ''
        this.answers = ['', '']
        this.getall()
      }).catch(e=>{
        try{
          if(e.response.status == 401){
            useToast().warning('Your session has expired, please log back in')
            this.$router.replace('/sign-in')
          }else{
            useToast().error(e.response.data.message)
          }
        }catch(err){
          useToast().warning(e.message)
        }
      })
    }
  },
  mounted(){
    ScrollComponent.bootstrap()
    this.getall()
    setCurrentPageTitle('Surveys')
  }
});
